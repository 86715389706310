import React from 'react';
import Layout from "../components/layout"
import GeneralSection from "../components/newComponents/generalSection";
import Seo from "../components/seo";
// import RecruitmentDatabaseForm from '../components/newComponents/recruitmentDatabaseForm';

const RecruitmentDatabasePage = () => (
  <Layout>
    <Seo title="Recruitment database" />
    <div className="main">
      <GeneralSection className="section-inside" style={{paddingBottom: 0}}>
        <header className="section__head">
          <h1 className="section__title title h2 text-gray">Recruitment Database</h1>
        </header>
      </GeneralSection>
      <GeneralSection>
        <div className="prose max-w-none mb-8 body-content-rich-text-bullets">If you’d like to be added to our Young Professionals mailing list and receive careers news from Genesis, please fill out this form:</div>
      </GeneralSection>
      <GeneralSection>
        <iframe src="https://flowcommunications.formstack.com/forms/recruitment_database" title="Recruitment Database" width="100%" height="720"></iframe>
      </GeneralSection>
    </div>
  </Layout>
);

export default RecruitmentDatabasePage;