import React from "react";

const GeneralSection = ({className, children, ...other}) => (
  <section className={className} {...other}>
    <div className="shell">
        {children}
    </div>
  </section>
);

export default GeneralSection;